
interface SelectItem {
  itemNameEn: string;
  itemCode: string;
}

interface FileListType {
  id: string;
  fileName: string;
  source: string;
}

interface SearchDataType {
  dealerCode: null;
  dealerSwtCode: null;
  dealerNameCn: null;
  dealerNameEn: null;
  region: null;
  vinNos: Array<string>;
  checkResult: Array<string>;
  checkingStatus: Array<string>;
  appeal: null;
  autoReturned: null;
  vinNo: string | null;
  claimId: string | null;
  claimType: string | null;
  holdingDateFrom: Moment | null;
  holdingDateTo: Moment | null;
  communicationTimes: null;
  queryDealerTrade: null;
  exemptionStatus?: true
}

interface TableType {
  checkResult: string;
  status: string;
  comments: string;
  dealerCode: string;
  dealerSwtCode: string;
  dealerNameCn: string;
  dealerNameEn: string;
  finalBonus: string;
  id: string;
  retailEligibilityStatus: string;
  isAppeal: string;
  isSubmitted: string;
  model: string;
  modelYear: string;
  offerTypeId: string;
  offerTypeName: string;
  progDesignId: string;
  programCode: string;
  programName: string;
  programNameCn: string;
  region: string;
  spotCheckingComments: string;
  vinNo: string;
  docMatchingResult: string;
  isManualMatching: string;
}

enum Status {
  Exemption = "99"
}

import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { message, Modal } from 'ant-design-vue';
import { h, createVNode, ref, onMounted, nextTick, reactive, toRefs, defineComponent } from 'vue';
import { UploadOutlined } from '@ant-design/icons-vue';
import UploadSourceData from './UploadSourceData/index.vue';
import ImportCheckingResult from './ImportCheckingResult/index.vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import {
  getSICCOChecingDetailList,
  getFindByGroupCode,
  getRegionApi,
  downloadDocuments,
  getDocumentNotice,
  getCheckingState,
  exportPageApi,
  getSourceData,
  deleteSourceDataApi,
  downloadSourceDataApi,
  checkingSubmit,
  getClaimType as getClaimTypeApi,
  exportDemoCarMileage as exportDemoCarMileageApi,
  getStatistics
} from '@/API/checking/SICCOCheckingDetail';
import Detail from './Detail/index.vue';
import Operation from './Operation/index.vue';
import { useRoute } from 'vue-router';
import moment, { Moment } from 'moment';
import Decimal from '@/utils/closing/decimal';
import useFormatDate from "@/utils/payment/useFormatDate";

interface RegionType {
  id: string;
  subRegionEn: string;
}

export default defineComponent({
  components: {
    UploadOutlined,
    UploadSourceData,
    Detail,
    Operation,
    ImportCheckingResult
  },
  beforeRouteEnter(to, from, next) {
    if (from.fullPath === '/checking/SICCOChecking') {
      next();
    } else {
      next('/checking/SICCOChecking');
    }
  },
  setup() {
    const route = useRoute();

    const programId = ref(route.params.programId);
    const offerTypeId = ref(route.params.offerTypeId);
    const programCode = ref(route.params.programCode);
    const programName = ref(route.params.programName);
    const programCategory = ref(route.params.programCategory);
    const checkingPlan = ref(route.params.checkingPlan);

    const batchName = ref(route.params.batchName);
    const submissionDate = ref(route.params.submissionDate);
    const checkingRoundId = ref(route.params.checkingRoundId);
    const bu = ref(route.params.bu === 'null' ? '' : route.params.bu);
    const offerTypeNameEn = ref(route.params.offerTypeNameEn);
    //region的数组
    const regionArray = ref<RegionType[]>([]);
    //claimType数组
    const claimTypeArray = ref<{ key: string; name: string }[]>([]);

    //筛选内容
    const searchData = reactive<SearchDataType>({
      dealerCode: null,
      dealerSwtCode: null,
      dealerNameCn: null,
      dealerNameEn: null,
      region: null,
      vinNos: [''],
      checkResult: [],
      checkingStatus: [],
      appeal: null,
      autoReturned: null,
      vinNo: '',
      claimId: null,
      claimType: null,
      holdingDateFrom: null,
      holdingDateTo: null,
      communicationTimes: null,
      queryDealerTrade: null
    });

    const searchDataTemporary = reactive<SearchDataType>({
      dealerCode: null,
      dealerSwtCode: null,
      dealerNameCn: null,
      dealerNameEn: null,
      region: null,
      vinNos: [''],
      checkResult: [],
      checkingStatus: [],
      appeal: null,
      autoReturned: null,
      vinNo: '',
      claimId: null,
      claimType: null,
      holdingDateFrom: null,
      holdingDateTo: null,
      communicationTimes: null,
      queryDealerTrade: null
    });

    const holdingPeriodChange = (val: Moment[]) => {
      searchData.holdingDateFrom = val[0];
      searchData.holdingDateTo = val[1];
    };

    // 批量查询
    const batchQuery = reactive({
      batchQueryTitle: '',
      batchQueryModal: false,
      batchQueryListTitle: ''
    });
    //Modal的输入框
    const textContentNo = ref<string>('');
    //输入框，用于控制焦点
    const input = ref();
    //显示的VIN数组
    const textNoArray = ref<string[]>([]);
    //空占位
    const batchPlaceholder = ref(['', '', '', '', '', '']);
    const cleanEmptyEleArray = (array: Array<string>) => {
      if (array.length > 1) {
        const tempArray = array.filter((item) => item !== '');
        tempArray.length || tempArray.push('');
        return tempArray;
      } else {
        return array;
      }
    };
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };

    // 批量查询按钮事件
    const batchQueryEvent = () => {
      batchQuery.batchQueryModal = true;
      batchQuery.batchQueryTitle = 'VIN No. Query';
      batchQuery.batchQueryListTitle = 'VIN No. List';
      textNoArray.value = cleanEmptyEleArray(searchData.vinNos)[0] === '' ? [] : cleanEmptyEleArray(searchData.vinNos);
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    // 批量文本内容
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('Please paste into the text box from excel');
        textContentNo.value = '';
        return;
      }
      textNoArray.value = textNoArray.value.concat(textContentNo.value.replace(/\r|\n|\s/g, ',').split(','));
      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      searchData.vinNos = [];
      searchData.vinNos = searchData.vinNos.concat(textNoArray.value);
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      searchData.vinNo = cleanEmptyEleArray(searchData.vinNos)[0] as string;
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      searchData.vinNos = [''];
      textNoArray.value = cleanEmptyEleArray(searchData.vinNos)[0] === '' ? [] : cleanEmptyEleArray(searchData.vinNos);
      setBatchPlaceholder();
      searchData.vinNo = null;
      input.value.focus();
    };

    //悬浮判断的id
    const hoverFileId = ref('-1');

    //Source Data文件列表
    const fileList = ref<FileListType[]>([]);

    const tableHeight = window.innerHeight - 590;

    const dataSource = ref<TableType[]>([]);

    const columns = reactive([
      {
        title: 'Claim ID',
        dataIndex: 'claimId',
        key: 'claimId',
        width: 100
      },
      {
        title: 'Claim Type',
        dataIndex: 'claimType',
        key: 'claimType',
        width: 100
      },
      {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        width: 100
      },
      {
        title: 'Dealer Name',
        dataIndex: 'dealerNameCn',
        key: 'dealerNameCn',
        width: 280
      },
      {
        title: 'Dealer Code',
        dataIndex: 'dealerCode',
        key: 'dealerCode',
        width: 120
      },
      {
        title: 'SWT Code',
        dataIndex: 'dealerSwtCode',
        key: 'dealerSwtCode',
        width: 120
      },
      {
        title: 'VIN',
        dataIndex: 'vinNo',
        key: 'vinNo',
        width: 190
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        width: 210
      },
      {
        title: 'Model Year',
        dataIndex: 'modelYear',
        key: 'modelYear',
        width: 120
      },
      {
        title: 'Retail Eligiblity Status',
        dataIndex: 'retailEligibilityStatus',
        key: 'retailEligibilityStatus',
        width: 220
      },
      {
        title: 'Dealer Trade',
        dataIndex: "dealerTrade",
        width: 100,
      },
      {
        title: 'WS Dealer Code',
        dataIndex: "dealerTradeWholeSaleCode",
        width: 160,
      },
      {
        title: 'WS Dealer Name',
        dataIndex: "dealerTradeWholeSaleNameCn",
        width: 260,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 180
      },
      {
        title: 'Check Result',
        dataIndex: 'checkResult',
        key: 'checkResult',
        width: 120
      },
      {
        title: 'Final Bonus',
        dataIndex: 'finalBonus',
        key: 'finalBonus',
        width: 120
      },
      {
        title: 'Appeal',
        dataIndex: 'isAppeal',
        key: 'isAppeal',
        width: 120
      },
      {
        title: 'Auto Returned',
        dataIndex: 'autoReturned',
        key: 'autoReturned',
        width: 120
      },
      {
        title: 'Holding Period DDL',
        dataIndex: 'retirementDate',
        key: 'holdingDate',
        width: 180
      },
      {
        title: 'Communication Times',
        dataIndex: 'communicationTimes',
        key: 'communicationTimes',
        width: 180
      },
      {
        title: 'Dealer communication Deadline',
        dataIndex: 'confirmDeadline',
        key: 'confirmDeadline',
        customRender: useFormatDate("MM/DD/YYYY"),
        width: 150
      },
      {
        title: 'Exemption Deadline',
        dataIndex: 'exemptionDeadline',
        key: 'exemptionDeadline',
        customRender: useFormatDate("MM/DD/YYYY"),
        width: 150
      },
      {
        title: 'Dealer Accepted Time',
        dataIndex: 'acceptedTime',
        key: 'acceptedTime',
        customRender: useFormatDate("MM/DD/YYYY"),
        width: 150
      },
      {
        title: 'Operation',
        key: 'action',
        slots: { customRender: 'action' },
        width: 120
      }
    ]);

    //子弹窗实例化
    const uploadsourcedata = ref<any>(null);
    const importCheckingResult = ref<any>(null);
    const deatilModal = ref<any>(null);
    const operationModal = ref<any>(null);
    type Key = ColumnProps['key'];
    const state = reactive<{
      selectedRowKeys: Key[];
      selectedRows: any[];
      loading: boolean;
    }>({
      selectedRowKeys: [], // Check here to configure the default column
      selectedRows: [],
      loading: false
    });

    const onSelectChange = (selectedRowKeys: Key[], selectedRows: any[]) => {
      state.selectedRowKeys = selectedRowKeys;
      state.selectedRows = selectedRows;
    };

    //分页
    const pagination = reactive({
      total: 0,
      currentPage: 1,
      pageSize: 20
    });

    //显示详情页
    const showDetail = (item: TableType) => {
      const option = {
        dealerClaimId: item.id,
        programId: programId.value,
        offerTypeId: offerTypeId.value,
        vinNo: item.vinNo
      };
      deatilModal.value.showDetail(option);
    };

    const getVINNo = (array: Array<Key>) => {
      const ids = [];
      for (let i = 0; i < array.length; i++) {
        const id = array[i];
        for (let j = 0; j < dataSource.value.length; j++) {
          if (id === dataSource.value[j].id) {
            ids.push(dataSource.value[j].vinNo);
          }
        }
      }

      return ids;
    };

    const getParams = (hasPage: boolean) => {
      const params: any = {
        programBu: bu.value,
        checkResult: searchDataTemporary.checkResult,
        checkingStatus: searchDataTemporary.checkingStatus,
        dealerCode: searchDataTemporary.dealerCode,
        dealerSwtCode: searchDataTemporary.dealerSwtCode,
        dealerNameCn: searchDataTemporary.dealerNameCn,
        dealerNameEn: searchDataTemporary.dealerNameEn,
        offerTypeId: offerTypeId.value,
        programCode: programCode.value,
        programId: programId.value,
        region: searchDataTemporary.region,
        appeal: searchDataTemporary.appeal,
        autoReturned: searchDataTemporary.autoReturned,
        vinNos:
          searchDataTemporary.vinNos.length === 1 && searchDataTemporary.vinNos[0] === ''
            ? []
            : searchDataTemporary.vinNos,
        vinNo:
          searchDataTemporary.vinNos.length >= 1 && searchDataTemporary.vinNos[0] !== ''
            ? ''
            : searchDataTemporary.vinNo,
        claimId: searchDataTemporary.claimId ?? null,
        claimType: searchDataTemporary.claimType ?? null,
        holdingDateBegin: searchDataTemporary.holdingDateFrom,
        holdingDateEnd: searchDataTemporary.holdingDateTo,
        communicationTimes: searchDataTemporary.communicationTimes,
        queryDealerTrade: searchDataTemporary.queryDealerTrade,
        exemptionStatus: searchDataTemporary.exemptionStatus
      };
      if (hasPage) {
        (params.size = pagination.pageSize), (params.current = pagination.currentPage - 1);
      }
      return params;
    };

    let sign = 0;
    //清除筛选条件
    const reset = () => {
      searchDataTemporary.dealerCode = null;
      searchDataTemporary.dealerSwtCode = null;
      searchDataTemporary.dealerNameEn = null;
      searchDataTemporary.dealerNameCn = null;
      searchDataTemporary.region = null;
      searchDataTemporary.vinNos = [''];
      searchDataTemporary.checkResult = [];
      searchDataTemporary.checkingStatus = [];
      searchDataTemporary.appeal = null;
      searchDataTemporary.vinNo = null;
      searchDataTemporary.autoReturned = null;
      searchDataTemporary.claimId = null;
      searchDataTemporary.claimType = null;
      searchDataTemporary.holdingDateFrom = null;
      searchDataTemporary.holdingDateTo = null;
      searchDataTemporary.communicationTimes = null;
      searchDataTemporary.queryDealerTrade = null;
      searchDataTemporary.exemptionStatus  = undefined;

      searchData.dealerCode = null;
      searchData.dealerSwtCode = null;
      searchData.dealerNameEn = null;
      searchData.dealerNameCn = null;
      searchData.region = null;
      searchData.vinNos = [''];
      searchData.checkResult = [];
      searchData.checkingStatus = [];
      searchData.appeal = null;
      searchData.autoReturned = null;
      searchData.vinNo = null;
      searchData.claimId = null;
      searchData.claimType = null;
      searchData.holdingDateFrom = null;
      searchData.holdingDateTo = null;
      searchData.communicationTimes = null;
      searchData.queryDealerTrade = null;
      searchData.exemptionStatus  = undefined;
    };
    //根据筛选条件查询表格
    const getTableDataByCondition = () => {
      const params = getParams(true);
      getSICCOChecingDetailList(params).then((res) => {
        const data = res.data;
        if (data.length > 0 && data[0].docMatchingResult === '') {
          dataSource.value = data;
        } else {
          if (sign === 0) {
            columns.splice(8, 0, {
              title: 'Document Collection',
              key: 'docMatchingResult',
              slots: { customRender: 'docMatchingResult' },
              width: 240
            });

            sign = 1;
          }
          dataSource.value = data;
        }
        state.selectedRowKeys = [];
        pagination.total = parseInt(res.totalNum);
      });
    };
    const searchTable = () => {
      pagination.currentPage = 1;
      searchDataTemporary.dealerCode = searchData.dealerCode;
      searchDataTemporary.dealerSwtCode = searchData.dealerSwtCode;
      searchDataTemporary.dealerNameEn = searchData.dealerNameEn;
      searchDataTemporary.dealerNameCn = searchData.dealerNameCn;
      searchDataTemporary.region = searchData.region;
      searchDataTemporary.vinNos = searchData.vinNos;
      searchDataTemporary.checkResult = searchData.checkResult;
      searchDataTemporary.checkingStatus = searchData.checkingStatus;
      searchDataTemporary.appeal = searchData.appeal;
      searchDataTemporary.autoReturned = searchData.autoReturned;
      searchDataTemporary.vinNo = searchData.vinNo;
      searchDataTemporary.claimId = searchData.claimId;
      searchDataTemporary.claimType = searchData.claimType;
      searchDataTemporary.holdingDateFrom = searchData.holdingDateFrom;
      searchDataTemporary.holdingDateTo = searchData.holdingDateTo;
      searchDataTemporary.communicationTimes = searchData.communicationTimes;
      searchDataTemporary.queryDealerTrade = searchData.queryDealerTrade;
      searchDataTemporary.exemptionStatus = searchData.checkingStatus.includes(Status.Exemption) ? true : undefined;
      getTableDataByCondition();
    };
    //获取source data列表
    const getSourceDataList = () => {
      getSourceData({
        programCode: programCode.value as string,
        offerTypeId: offerTypeId.value as string
      }).then((res) => {
        fileList.value = res;
      });
    };
    //删除SourceData
    const deleteSourceData = (id: string) => {
      let sign = false;
      for (let i = 0; i < fileList.value.length; i++) {
        if (fileList.value[i].id == id && fileList.value[i].source) {
          sign = true;
        }
      }

      if (sign) {
        message.error('It is an exemption file and cannot be deleted!');
        return;
      }
      Modal.confirm({
        title: 'Please confirm',
        content: 'Do you confirm to delete current file?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk() {
          deleteSourceDataApi({ sourceDataId: id }).then((res: any) => {
            message.success('Successfully deleted');
            getSourceDataList();
          });
        },
        onCancel() {
          console.log('Cancel');
        }
      });
    };
    //下载SourceData
    const downloadSourceData = (item: FileListType) => {
      downloadSourceDataApi({ sourceDataId: item.id }).then((res: string) => {
        const downloadElement = document.createElement('a');
        downloadElement.href = res;
        downloadElement.download = item.fileName;
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
      });
    };
    //显示上传抽屉
    const showUpload = () => {
      uploadsourcedata.value.showDrawer({
        offerTypeId: offerTypeId.value,
        programCode: programCode.value
      });
    };
    const submitParams = ref<any>({});
    const batchSubmit = () => {
      if (dataSource.value.length === 0) {
        message.error('Please select data for operation');
        return;
      }
      if (state.selectedRowKeys.length === 0) {
        submitParams.value = Object.assign(getParams(false), {
          ids: [],
          batchName: batchName.value,
          batchSubmissionDate: submissionDate.value,
          checkingRoundId: checkingRoundId.value,
          confirm: 'N',
          programNameEn: programName.value,
          offerTypeNameEn: offerTypeNameEn.value,
          bu: bu.value,
          programId: programId.value
        });

        checkingSubmit(submitParams.value).then((res) => {
          if (res.code === '0') {
            const params = getParams(true);
            getStatistics(params).then((res2: any) => {
              const claimNo = res2.totalNum;
              const ApprovedNo = res2.checkingResultList.filter((row: any) => {
                return row.checkResult === 'Approved';
              }).length;
              const totalBonus = res2.checkingResultList.reduce((total: any, row: any) => {
                // return total + parseFloat(parseFloat(row.finalBonus).toFixed(2));
                return total.plus(new Decimal(row.finalBonus));
              }, new Decimal(0));
              Modal.confirm({
                width: 500,
                title: 'Tips',
                content: createVNode('p', {}, [
                  createVNode('div', {}, `Do you confirm submit below claims to SIPO?`),
                  createVNode('span', {}, `Claim No: ${claimNo}`),
                  createVNode('span', { style: { 'margin-left': '40px' } }, `Approved No: ${ApprovedNo}`),
                  createVNode('span', { style: { 'margin-left': '40px' } }, `Total bonus: ${totalBonus.round(2)}`)
                ]),
                okText: 'Confirm',
                cancelText: 'Cancel',
                onOk: () => {
                  submitParams.value.confirm = 'Y';
                  checkingSubmit(submitParams.value).then((res) => {
                    if (res.code === '0') {
                      getTableDataByCondition();
                      message.success('Request submitted successfully. Please query result in task center');
                    } else {
                      message.error(res.message);
                    }
                  });
                }
              });
            });
          } else {
            message.error(res.message);
          }
        });
      } else {
        // let sign = 0;
        // for (let i = 0; i < state.selectedRowKeys.length; i++) {
        //   const id = state.selectedRowKeys[i];
        //   for (let j = 0; j < dataSource.value.length; j++) {
        //     if (id === dataSource.value[j].id) {
        //       const status = dataSource.value[j].status.toLowerCase();

        //       if (
        //         status !== 'sicco init' &&
        //         status !== 'sipo rejected' &&
        //         status !== 'spot checking rejected' &&
        //         status !== 'dealer rejected'
        //       ) {
        //         sign = 1;
        //       }
        //       if (dataSource.value[j].checkResult.toLowerCase() === 'uncheck ') {
        //         sign = 2;
        //       }
        //     }
        //   }
        // }
        // if (sign === 1) {
        //   message.error('Submitted records cannot be submit');
        //   return;
        // } else if (sign === 2) {
        //   message.error('Uncheck result cannot submit');
        //   return;
        // }
        submitParams.value = {
          ids: state.selectedRowKeys,
          batchName: batchName.value,
          batchSubmissionDate: submissionDate.value,
          checkingRoundId: checkingRoundId.value,
          programId: programId.value,
          programNameEn: programName.value,
          offerTypeNameEn: offerTypeNameEn.value,
          programCode: programCode.value,
          offerTypeId: offerTypeId.value,
          bu: bu.value,
          confirm: 'N',
          exemptionStatus: searchDataTemporary.checkingStatus.includes(Status.Exemption) ? true: undefined
        };
        const claimNo = state.selectedRows.length;
        const ApprovedNo = state.selectedRows.filter((row: any) => {
          return row.checkResult === 'Approved';
        }).length;
        const totalBonus = state.selectedRows.reduce((total: any, row: any) => {
          // return total + parseFloat(parseFloat(row.finalBonus).toFixed(2));
          return total.plus(new Decimal(row.finalBonus));
        }, new Decimal(0));
        checkingSubmit(submitParams.value).then((res) => {
          if (res.code === '0') {
            Modal.confirm({
              width: 500,
              title: 'Tips',
              content: createVNode('p', {}, [
                createVNode('div', {}, `Do you confirm submit below claims to SIPO?`),
                createVNode('span', {}, `Claim No: ${claimNo}`),
                createVNode('span', { style: { 'margin-left': '40px' } }, `Approved No: ${ApprovedNo}`),
                createVNode('span', { style: { 'margin-left': '40px' } }, `Total bonus: ${totalBonus.round(2)}`)
              ]),
              okText: 'Confirm',
              cancelText: 'Cancel',
              onOk: () => {
                submitParams.value.confirm = 'Y';
                checkingSubmit(submitParams.value).then((res) => {
                  if (res.code === '0') {
                    getTableDataByCondition();
                    message.success('Request submitted successfully. Please query result in task center');
                  } else {
                    message.error(res.message);
                  }
                });
              }
            });
          } else {
            message.error(res.message);
          }
        });
      }
    };
    //显示操作抽屉
    const showOperation = (operation: string) => {
      if (dataSource.value.length === 0) {
        message.error('Please select data for operation');
        return;
      }
      if (state.selectedRowKeys.length !== 1) {
        message.error('Only one record is allowed to be operated');
        return;
      }
      //判断是否是 Pending和Reject
      if (operation === 'Pending' || operation === 'Reject') {
        let record = null;
        const id = state.selectedRowKeys[0];
        for (let j = 0; j < dataSource.value.length; j++) {
          if (id === dataSource.value[j].id) {
            record = dataSource.value[j];
          }
        }
        operationModal.value.showOperationBySelect(operation, record, searchDataTemporary.exemptionStatus);
      } else {
        let record = null;
        for (let i = 0; i < state.selectedRowKeys.length; i++) {
          const id = state.selectedRowKeys[i];
          for (let j = 0; j < dataSource.value.length; j++) {
            if (id === dataSource.value[j].id) {
              record = dataSource.value[j];
            }
          }
        }
        operationModal.value.showOperationBySelect(operation, record, searchDataTemporary.exemptionStatus);
      }
    };
    //更改分页页签
    const pageChange = (page: number) => {
      pagination.currentPage = page;
      state.selectedRowKeys = [];
      getTableDataByCondition();
    };
    //改变每页大小
    const sizeChange = (page: number, pageSize: number) => {
      pagination.pageSize = pageSize;
      pagination.currentPage = 1;
      state.selectedRowKeys = [];
      getTableDataByCondition();
    };
    //显示ImportCheckingResult抽屉
    const showImportCheckingResult = () => {
      const option = {
        programId: programId.value,
        offerTypeId: offerTypeId.value
      };
      importCheckingResult.value.showDrawer(option);
    };
    //导出功能
    const exportPage = () => {
      //未选择信息，根据查询结构，进行导出
      if (state.selectedRowKeys.length !== 0) {
        const params = {
          vinNos: getVINNo(state.selectedRowKeys),
          offerTypeId: offerTypeId.value,
          programId: programId.value,
          programCode: programCode.value,
          programBu: bu.value,
          claimAppIdList: state.selectedRowKeys,
          exemptionStatus: searchDataTemporary.checkingStatus.includes(Status.Exemption) ? true: undefined
        };
        exportPageApi(params);
      } else {
        exportPageApi(getParams(false));
      }
    };
    const exportDemoCarMileage = () => {
      exportDemoCarMileageApi({
        prodId: programId.value,
        offerTypeId: offerTypeId.value
      });
    };
    const checkingResultArray = ref<SelectItem[]>([]);
    const checkingStatusArray = ref<SelectItem[]>([]);
    //获取Check Result，Status的下拉框参数
    const getSelect = () => {
      getFindByGroupCode({ groupCode: 'checking_result' }).then((res) => {
        checkingResultArray.value = [];
        checkingResultArray.value = res;
      });
      getCheckingState().then((res) => {
        const arr = [];
        for (let i = 0; i < res.length; i++) {
          arr.push({
            itemNameEn: res[i].name,
            itemCode: res[i].code
          });
        }
        checkingStatusArray.value = [];
        checkingStatusArray.value = arr;
      });
    };
    //获取region数组
    const getRegion = () => {
      getRegionApi({ bu: bu.value }).then((res) => {
        regionArray.value = res;
      });
    };
    //获取claimType数组
    const getClaimType = async () => {
      claimTypeArray.value = await getClaimTypeApi();
    };

    const downParam = ref<any>({});
    //DownLoad事件
    const generateDownloadTask = () => {
      if (state.selectedRowKeys.length === 0) {
        downParam.value = getParams(false);
      } else {
        downParam.value = {
          claimAppIdList: state.selectedRowKeys,
          programId: programId.value,
          offerTypeId: offerTypeId.value,
          programCode: programCode.value,
          exemptionStatus: searchDataTemporary.checkingStatus.includes(Status.Exemption) ? true: undefined
        };
      }
      getDocumentNotice(downParam.value).then((res) => {
        if (res.code !== '0') {
          message.error(res.message);
          return;
        }
        downParam.value.totalDocAmount = res.data.totalDocAmount;
        downParam.value.totalFileSize = res.data.totalDocSize;
        downParam.value.offerTypeNameEn = offerTypeNameEn.value;
        Modal.confirm({
          title: 'Do you confirm to submit download request ?',
          icon: createVNode(ExclamationCircleOutlined),
          width: 600,
          content: createVNode('div', {}, [
            createVNode('div', {}, `Total documents number: ${res.data.totalDocAmount}`),
            createVNode('div', {}, `Total document size: ${res.data.totalDocSize}(MB)`),
            createVNode(
              'div',
              {},
              `(Please notice that task which exceed 50(MB) size will execute in the evening, you can query download result in package task center in next day ）`
            )
          ]),
          onOk() {
            downloadDocuments(downParam.value).then((res) => {
              if (res.data.downloadUrl === null || res.data.downloadUrl === '') {
                message.success(
                  'Download request submitted successfully. Please query download result in package task center.'
                );
              } else {
                const downloadElement = document.createElement('a');
                downloadElement.href = res.data.downloadUrl;
                downloadElement.download = res.data.fileName;
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement);
              }
            });
          },
          onCancel() {
            console.log('Cancel');
          },
          class: 'test',
          okText: '  Yes  '
        });
      });
    };
    onMounted(() => {
      getSourceDataList();
      getTableDataByCondition();
      getSelect();
      getRegion();
      getClaimType();
    });
    return {
      searchTable,
      generateDownloadTask,
      sizeChange,
      pageChange,
      batchSubmit,
      reset,
      checkingResultArray,
      checkingStatusArray,
      exportPage,
      regionArray,
      claimTypeArray,
      ...toRefs(pagination),
      showDetail,
      showUpload,
      getSourceDataList,
      deatilModal,
      operationModal,
      showOperation,
      columns,
      dataSource,
      tableHeight,
      uploadsourcedata,
      ...toRefs(state),
      onSelectChange,
      importCheckingResult,
      input,
      fileList,
      hoverFileId,
      deleteSourceData,
      downloadSourceData,
      getTableDataByCondition,
      showImportCheckingResult,
      programCode,
      programName,
      programCategory,
      checkingPlan,
      textContentNo,
      batchPlaceholder,
      clearBatchQuery,
      ...toRefs(searchData),
      batchQueryEvent,
      ...toRefs(batchQuery),
      textNoArray,
      closeBatchQueryModal,
      textChangEvent,
      exportDemoCarMileage,
      holdingPeriodChange,
      moment
    };
  }
});
